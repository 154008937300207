import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import { NoteList } from "./list";
import NoteForm from "./form";
import { deleteNote, fetchNotes, toggleView } from "../../redux/modules/note";
import { RoleRequired } from "../auth/role-required";
import { permissions } from "../../constant/permissions";
import { usePagination } from "../../components/common/pagination";

const NotesComponent = ({
  entity,
  entityType,
  notes,
  deleteNote,
  toggleView,
  loading,
  fetchNotes,
}) => {
  const [paginatedNotes, paginationComponent, setCurrentPage] = usePagination(
    notes,
    3
  );
  const totalNotes = notes ? notes.length : 0;

  useEffect(() => {
    fetchNotes(entity.id, entityType);
  }, []);

  if (loading) {
    return (
      <Segment padded={true}>
        <Dimmer active inverted>
          <Loader active inline="centered" />
        </Dimmer>
      </Segment>
    );
  }

  return (
    <Segment>
      {totalNotes > 3 && paginationComponent}
      {paginatedNotes && paginatedNotes.length > 0 && (
        <NoteList
          notes={paginatedNotes}
          deleteNote={deleteNote}
          toggleView={toggleView}
        />
      )}
      {totalNotes > 0 && <Divider />}
      <RoleRequired staff={true} permission={permissions.boardEdit}>
        <NoteForm
          key={`form-${notes ? notes.length : 0}`}
          entity={entity}
          entityType={entityType}
          onSubmit={() => {
            setCurrentPage(1);
          }}
        />
      </RoleRequired>
    </Segment>
  );
};

function mapStateToProps(state, props) {
  return {
    notes: state.note[props.entityType] || null,
    loading: state.note.pending,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ deleteNote, fetchNotes, toggleView }, dispatch);
}

export const Notes = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesComponent);
export default Notes;

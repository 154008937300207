export const translationEN = {
  "estateTypes.apartment": "Apartment",
  "estateTypes.garage": "Garage",
  "estateTypes.office": "Office",
  "estateTypes.store": "Store",
  "estateTypes.parking_space": "Parking Space",
  "estateTypes.parking space": "Parking Space",
  "estateTypes.studio": "Studio",
  "estateTypes.shop": "Shop",
  "estateTypes.atelier": "Atelier",
  "estateTypes.restaurant": "Restaurant",
  "estateTypes.gym": "Gym",
  "estateTypes.laboratory": "Laboratory",
  "estateTypes.other": "Other",
  "paymentMethods.null": "None",
  "paymentMethods.bank": "Bank",
  "paymentMethods.cash": "Cash",
  "paymentMethods.card": "Card",
  "paymentMethods.epay": "E-Pay",
  "paymentMethods.easypay": "EasyPay",
  "paymentMethods.number": "Number",
  "paymentMethods.balance": "Balance",
  "chargeStatus.null": "None",
  "chargeStatus.unsettled": "Unsettled",
  "chargeStatus.settled": "Settled",
  "chargeStatus.partial": "Partial",
  "ticketStates.all": "All",
  "ticketStates.open": "Open",
  "ticketStates.in_progress": "In Progress",
  "ticketStates.resolved": "Resolved",
  "ticketStates.invalid": "Invalid",
  "ticketStates.closed": "Closed",
  "invoiceStates.pending": "Pending",
  "invoiceStates.paid": "Paid",
  "ticketTypes.null": "None",
  "ticketTypes.technical": "Technical",
  "ticketTypes.other": "Other",
  "transactionTypes.null": "None",
  "transactionTypes.general": "General",
  "transactionTypes.income": "Income",
  "transactionTypes.expense": "Expense",
  "estateCustomerTypes.tenant": "Tenant",
  "estateCustomerTypes.owner": "Owner",
  "ticketUserTypes.assignee": "Assignee",
  "userActions.edit": "Edit",
  "userActions.delete": "Delete",
  "userActions.respond": "Respond",
  "userActions.comment": "Comment",
  "labels.date": "Date",
  "labels.createdAt": "Created At",
  "labels.title": "Title",
  "labels.status": "Status",
  "labels.comments": "Comments",
  "labels.edited": "Edited",
  "labels.id": "ID",
  "labels.name": "Name",
  "labels.block": "Block",
  "labels.author": "Author",
  "labels.assignee": "Assignee",
  "labels.content": "Content",
  "labels.description": "Description",
  "labels.detailed_description": "Detailed description",
  "labels.published": "Published",
  "labels.not_published": "Not Published",
  "labels.none": "None",
  "labels.incomes": "Incomes",
  "labels.expenses": "Expenses",
  "labels.amount": "Amount",
  "labels.source": "Source",
  "labels.payments": "Payments",
  "labels.payment": "Payment",
  "labels.action": "Action",
  "labels.category": "Category",
  "labels.resource": "Resource",
  "labels.payment_number": "Payment Number",
  "labels.user": "User",
  "labels.chosen_funds": "Chosen Funds",
  "labels.type": "Type",
  "labels.type_lowerc": "type",
  "labels.all_funds": "All Funds",
  "labels.select_report_type": "Select Report Type",
  "labels.save": "Save",
  "labels.multiply": "Multiply",
  "labels.from": "From",
  "labels.transaction_categories": "Transaction Categories",
  "labels.add": "Add",
  "labels.submit": "Save",
  "labels.minimum": "Minimum",
  "labels.total_amount": "Total Amount",
  "labels.total": "Total",
  "labels.charge_amount_left": "Charge Amount Left",
  "labels.new_obligation": "New Obligation to",
  "labels.obligation_change": "Obligation Change",
  "labels.direction": "Direction",
  "labels.select_direction": "Select Direction",
  "labels.toall": "To All",
  "labels.month": "Month",
  "labels.client_number": "Client Number",
  "labels.deposited_amount": "Deposited Amount",
  "labels.prepaid_amount": "Prepaid Amount",
  "labels.paid_amount": "Paid Amount",
  "labels.official_receipt_cap": "Official Receipt",
  "labels.print": "Print",
  "labels.amount_due": "Amount Due",
  "labels.yes": "Yes",
  "labels.yes_low": "yes",
  "labels.no": "No",
  "labels.and": "and",
  "labels.in": "in",
  "labels.here": "here",
  "labels.names": "Names",
  "labels.day": "Day",
  "labels.days": "Days",
  "labels.apply": "Apply",
  "labels.remove": "Remove",
  "labels.filters": "Filters",
  "labels.search": "Search",
  "labels.change": "Change",
  "labels.create": "Create",
  "labels.deletion": "Deletion",
  "labels.delete": "Delete",
  "labels.close": "Close",
  "labels.forwards": "Forwards",
  "labels.enter": "Enter",
  "labels.exit": "Exit",
  "labels.proceed": "Proceed",
  "labels.confirm_continue": "Are you sure you want to continue?",
  "labels.fund": "Fund",
  "labels.csv_file": "CSV file",
  "labels.required_fields": "Required fields",
  "labels.expense_csv_fields": "Expense CSV fields",
  "labels.error": "Error",
  "labels.from_date": "From Date",
  "labels.to_date": "To Date",
  "labels.all": "All",
  "side.management": "Management",
  "side.locations": "Locations",
  "side.properties": "Properties",
  "side.signals": "Signals",
  "side.dashboard": "Dashboard",
  "side.residents": "Residents",
  "side.accounting": "Accounting",
  "titles.full_report": "Full Report",
  "titles.report_debt_deposits": "Report Debts and Deposits",
  "titles.debts_income": "Debts and Income",
  "titles.cash_report": "Cash Report",
  "titles.from_date": "From Date",
  "titles.to_date": "To Date",
  "placeholders.all_funds": "All Funds",
  "placeholders.begin_writing_to_add": "Add a new value",
  "titles.date_of_obligation": "Date of Obligation",
  "titles.duties_paid": "Duties Paid",
  "roles.admin": "Administrator",
  "roles.staff": "Staff",
  "roles.user": "User",
  "blockdash.virt_dash": "Virtual Dashboard Enabled",
  "blockdash.auto_gen": "Automatic Generation of Monthly Obligations Enabled",
  "blockdash.online_payments": "Online Payments Enabled",
  "blockdash.notifications":
    "Automatic Notifications for Current Obligations Enabled",
  "resources.null": "All",
  "resources.audit": "Tracking",
  "resources.billing-attribute": "Billing Attribute",
  "resources.billing-forecast": "Billing Forecast",
  "resources.billing-model": "Billing Model",
  "resources.billing-monthly-report": "Monthly Report",
  "resources.billing-summary": "Billing Summary",
  "resources.block": "Object",
  "resources.board-post": "Board Post",
  "resources.charge": "Obligation",
  "resources.charge_to": "Charge to",
  "resources.collection": "Collection",
  "resources.collection-stats": "Collection statistics",
  "resources.estate": "Estate",
  "resources.estate_capital": "Estate",
  "resources.estate-customers": "Resident",
  "resources.file": "Document",
  "resources.file-dir": "Directory",
  "resources.note": "Note",
  "resources.user-role": "Role",
  "resources.billing-attribute-bulk-upsert": "Bulk billing attributes",
  "resources.fund-transaction": "Transaction",
  "resources.checkout": "Checkout",
  "resources.bgn": "BGN",
  "resources.bgn_no_dot": "BGN",
  "profile.remove_link": "Remove link between",
  "profile.remove_user_link":
    "Are you sure you want to remove the link between user",
  "profile.and_property": "and property",
  "profile.other_properties": "other properties",
  "viber.quickacess_with": "Quick access with",
  "viber.chatbot": "Viber chatbot",
  "viber.chatbot_info":
    "Through our Viber chatbot you will receive up-to-date information related to your apartment ownership and you can quickly and easily check your current liabilities.",
  "viber.scan_code":
    "Scan the code to start a chat and follow the instructions.",
  "viber.clickhere": "Click here",
  "viber.click_if_app":
    ", if you are using a mobile browser and have already installed the application",
  "logActions.null": "All",
  "logActions.create": "add",
  "logActions.update": "change",
  "logActions.destroy": "delete",
  "logActions.reconcile": "confirm",
  "logActions.reconcile_cap": "Confirm",
  "logActions.remove_active_sessions": "delete active sessions",
  "logActions.cancel_payments": "cancel payments",
  "logActions.perform_create_payment_return": "return funds to balance",
  "logActions.regenerate": "regenerate",
  "logActions.init": "started",
  "paymentTypes.null": "All",
  "paymentTypes.cash": "Cash",
  "paymentTypes.bank": "Bank transfer",
  "permissions.controlPanelView": "Control panel view",
  "permissions.billingAccountAdd": "Add cash register",
  "permissions.billingAccountEdit": "Edit cash register",
  "permissions.billingAccountDelete": "Delete cash register",
  "permissions.billingAccountView": "View cash register",
  "permissions.billingAccountViewAtDisposal": "View cash register availability",
  "permissions.billingAccountViewBalance": "View cash register balance",
  "permissions.billingAttributeAdd": "Add billing attribute",
  "permissions.billingAttributeEdit": "Edit billing attribute",
  "permissions.billingModelAdd": "Add billing model",
  "permissions.billingModelEdit": "Edit billing model",
  "permissions.billingReportView": "View report",
  "permissions.billingReportEdit": "Change report (lock, unlock)",
  "permissions.billingSettlementDelete": "Delete Payment",
  "permissions.billingTransactionCreate": "Add Transaction",
  "permissions.billingTransactionDelete": "Delete Transaction",
  "permissions.billingTransactionView": "View Transactions",
  "permissions.billingTransactionEdit": "Edit Transaction",
  "permissions.billingConfirmCollection": "Confirm Payment",
  "permissions.blockAdd": "Add Object",
  "permissions.blockEdit": "Edit Object",
  "permissions.boardEdit": "Edit Virtual Board",
  "permissions.boardEditPost": "Edit Virtual Board Posts",
  "permissions.chargeAdd": "Add Obligation",
  "permissions.chargeDelete": "Delete Obligation",
  "permissions.collectionGlobalView": "View Collections Section",
  "permissions.customerEdit": "Edit Customer Profile",
  "permissions.customerView": "View Customers",
  "permissions.estateCustomerAdd": "Add Customer to Estate",
  "permissions.estateCustomerDelete": "Remove Customer from Estate",
  "permissions.estateEdit": "Edit Estate",
  "permissions.estateGlobalView": "View All Managed Estates",
  "permissions.estateSettleAmount": "Add Payment to Estate",
  "permissions.managementBulkImport": "Bulk Edit",
  "permissions.noteEdit": "Edit Note",
  "permissions.notesView": "View Notes",
  "permissions.teamAdd": "Add Account to Team",
  "permissions.teamDelete": "Remove Account from Team",
  "permissions.teamEdit": "Edit Team Accounts",
  "permissions.teamView": "View Team Accounts",
  "permissions.ticketEdit": "Edit Ticket",
  "permissions.ticketGlobalView": "View All Tickets",
  "billing.unt": "unit",
  "billing.change_of": "Change of",
  "billing.newattr": "New Attribute",
  "billing.value": "Value",
  "billing.attributes": "Attributes",
  "billing.full_payment": "Full Payment",
  "billing.save": "Save",
  "billing.budget": "Budget",
  "billing.reports": "Reports",
  "billing.payments": "Payments",
  "billing.transactions": "Transactions",
  "billing.obligations": "Obligations",
  "billing.tills": "Cash Registers",
  "billing.payment_model": "Billing Model",
  "billing.old_period": "Old Period",
  "billing.balance_to": "Balance to",
  "billing.total_per_floor": "Total Balance per Floor",
  "billing.prepaid_fees": "Prepaid Fees",
  "billing.obligations_to": "Obligations to",
  "billing.accrued_period": "Accrued during the period",
  "billing.collected_contr": "Collected Installments",
  "billing.other_incomes": "Other Incomes",
  "billing.total_income": "Total Income",
  "billing.total_expense": "Total Expenses",
  "billing.collection_remaining": "Total Remaining to Collect",
  "billing.monthly_reports": "Monthly Reports",
  "billing.balances_per_month": "Balances per Period",
  "billing.transaction_reversed_on": "Transaction reversed on",
  "billing.change_Transaction": "Change Transaction",
  "billing.from_type": "From type",
  "billing.new_order": "New order",
  "billing.withdraw": "Withdraw",
  "billing.cash": "Cash",
  "billing.bank": "Bank",
  "billing.easypay": "EasyPay",
  "billing.epay": "ePay",
  "billing.transaction_date": "Transaction date",
  "billing.expenses": "Разходи",
  "block.report_to": "Report to",
  "block.no_reports": "No reports available",
  "block.available": "Available",
  "block.current_liabilities": "Current liabilities",
  "block.arrears": "Arrears",
  "block.clients": "Clients",
  "block.signals_info":
    "\nSignals are a means of communication of problems and suggestions,\nrelated to the apartment building to which you belong.\n",
  "block.signal": "Signal",
  "block.welcome": "Welcome!",
  "block.info_functionality":
    "\nWould you like to learn more about the functionality of the dashboard?\n",
  "block.enter": "Enter me",
  "block.object_archived": "This object is archived",
  "block.notes": "Notes",
  "block.messages": "Messages",
  "block.info": "Information",
  "block.documents": "Documents",
  "block.location_balance": "Balance for objects",
  "block.current_balances": "Current balances",
  "block.reference_monthly_rep":
    "\nThe reference for the period is based on the published monthly reports. With\nthis checkmark you can only display the current balances.\n",
  "block.download": "Download",
  "block.reference_per_loc": "Reference for balances by objects",
  "block.new_building": "New building",
  "block.archive": "Archive",
  "block.to_archive": "Archiving of",
  "block.archiving_disable_attr":
    "Archiving the object will disable all automatic processes, \nincluding online payments. Are you sure you want to continue?",
  "block.address": "Address",
  "block.auto_generate_rep": "Automatic generation of monthly liabilities",
  "block.configured_budget_info":
    "\nThe configured budget is used to automatically create\nmonthly liabilities. Liabilities are generated on the last day of\nthe month and are available for online payment from the 1st of the following month.\n",
  "block.auto_notifications_debts":
    "Automatic notifications for current liabilities",
  "block.auto_email_notifs":
    "\nAutomatic sending of e-mail notifications with the current liabilities\nto each client. They are sent on the first Wednesday of the month.\n",
  "block.virtual_dash": "Virtual dashboard",
  "block.allows_access_email":
    "\nAllows access to the system of all residents with configured\ne-mail address.\n",
  "block.epay_easypay_integr": "Epay / EasyPay integration",
  "block.current_payments_info":
    "\nCurrent liabilities become available for payment through the ePay and EasyPay systems.\nCollected amounts are transferred to an account of your choice.\nTransaction fees charged by the provider are added to the fixed monthly subscription.\n",
  "block.card_payments": "Card Payments",
  "block.allows_card_payment":
    "\nAllows owners and residents to pay their current liabilities with a card directly from the virtual dashboard of Smart Entrance. For card payments, the transaction fee is included and is paid by the client.\n",
  "block.none_available": "No objects available",
  "block.create_new": "Create new",
  "block.active": "Active",
  "block.archive": "Archive",
  "block.new_object": "New object",
  "block.number_of_properties": "Number of properties",
  "board.no_new_msg": "No new messages",
  "board.new_message": "New message",
  "board.change_message": "Change message",
  "board.upload_new_file": "Upload new file",
  "board.attached_documents": "Attached documents",
  "board.uploading": "Uploading..",
  "board.notif_to_inhabitant": "Notification to residents",
  "board.notifs_mail_viber":
    "\nResidents associated with this object receive notifications by email and Viber\nfor every new message. Uncheck to disable this\nfunctionality. Notifications are sent after about 5 minutes, which\nallows you to make corrections or remove the message.\n",
  "common.back": "Back",
  "common.widescreen": "Widescreen",
  "common.confirm_deletion": "Confirm Deletion",
  "common.want_to_delete": "Do you want to delete",
  "common.forever": "forever",
  "common.this_op_irreversible": "This operation is irreversible",
  "common.page": "Page",
  "common.page_of": "of",
  "estate.add_inhabitat": "Adding a resident",
  "estate.inhabitat": "Resident",
  "estate.choose_profile": "Choose profile",
  "estate.profile": "Profile",
  "estate.select_type": "Select type",
  "estate.tenant": "Tenant",
  "estate.owner": "Owner",
  "estate.client_number": "Client Number:",
  "estate.floor": "Floor",
  "estate.pay": "Pay",
  "estate.return_deposit": "Return deposit",
  "estate.add_obligation": "Add obligation",
  "estate.auto_bill_off": "Automatic billing for this property is disabled",
  "estate.no_periodic_bill": "No new periodic obligations will be charged",
  "estate.has_debt": "only with unpaid obligations",
  "estate.unset_debt": "unpaid amount",
  "estate.count_debt": "number of obligations",
  "estate.ap1": "Apt. 1",
  "estate.has_debt": "Only with unpaid obligations",
  "estate.unsettled_amount": "Unpaid amount",
  "estate.from": "From",
  "estate.to": "To",
  "estate.add_estate": "Adding a property",
  "estate.change_estate": "Changing a property",
  "estate.preferred_payment_method": "Preferred payment method",
  "estate.pick_payment_method": "Choose payment method",
  "estate.automatic_tax": "Automatic billing",
  "estate.prefer": "Prefers",
  "estate.inst_to": "Installment to Et.s.",
  "estate.sorting": "Sorting",
  "estate.desc_liabiliies": "Liabilities (descending)",
  "estate.asc_liabiliies": "Liabilities (ascending)",
  "estate.properties": "Properties",
  "estate.new_property": "New property",
  "estate.property_search": "Property search",
  "estate.branch_numb": "Branch No",
  "estate.liab_own_estate": "Liabilities to my properties",
  "estate.proceed_forward_stripe":
    'After clicking the "Continue" button you will be redirected to the\nStripe website to pay',
  "estate.payment_to": "Payment of liabilities to",
  "estate.new_payment_to": "New payment to",
  "estate.automatic_payment": "Automatic repayment of current liabilities",
  "estate.checkmark_debt_order":
    "\nBy default, current liabilities are repaid from\noldest to newest. By checking this box you can\ndisable this functionality and choose exactly which\nliabilities to pay below.\n",
  "estate.deposit_return": "Deposit return",
  "estate.count_unpaid_debt": "Number of unpaid liabilities",
  "estate.payment_details": "Payment details",
  "estate.transaction_fee": "Transaction fee",
  "estate.prepay_1_months": "1 month",
  "estate.prepay_3_months": "3 months",
  "estate.prepay_6_months": "6 months",
  "estate.prepay_12_months": "12 months",
  "estate.choose_prepay_period": "Choose prepayment period",
  "estate.current_debt": "Current charges",
  "estate.typical_monthly_payment": "Current monthly charge",
  "estate.enable_prepay": "I want to prepay for several months",
  "estate.prepay_amount": "Prepaid amount",
  "estate.total_amount": "Total amount",
  "file.common": "Common",
  "file.no_attached_documents": "No attached documents",
  "file.change_directory": "Change directory",
  "file.document_select": "Document selection",
  "file.directory": "Directory",
  "file.folder": "Folder",
  "file.attach": "Attach",
  "management.see": "See",
  "management.download": "Download",
  "management.usage": "Usage",
  "management.payment_method": "Payment method",
  "management.from_date": "From Date",
  "management.details": "Details",
  "management.archived": "Archived",
  "management.select_object": "Select object",
  "management.customer_number": "Customer Number",
  "management.property": "Property",
  "management.amount_to_distribute": "Amount to distribute",
  "management.per_basis": "On basis",
  "management.email": "E-Mail",
  "management.mobile_phone": "Mobile phone",
  "management.contacts": "Contacts",
  "management.income_expenses": "Income and expenses",
  "management.intitial_entrys": "Initial number of input rows",
  "management.select_template": "Select input template",
  "management.table_copied_to_memory": "The table has been copied to memory!",
  "management.copy": "Copy",
  "management.data_saved_successfuly": "Data saved successfully!",
  "management.error_saving":
    "An error occurred while saving. Please check the input and try again",
  "management.add_row_above": "Add row above",
  "management.add_row_below": "Add row below",
  "management.delete_row": "Delete row",
  "management.no_payments": "No payments received",
  "management.unconfirmed_payments": "Unconfirmed payments",
  "management.summary": "Summary",
  "management.per_month": "Per month",
  "management.per_object": "Per object",
  "management.per_day": "Per day",
  "management.history": "History",
  "management.collected_amount": "Collected amount from",
  "management.confirm_collected":
    "Are you sure you want to confirm the collected",
  "management.collected_for_period": "Collected for the period",
  "management.unconfirmed": "Unconfirmed",
  "management.confirmed": "Confirmed",
  "management.delete_role": "Delete role",
  "management.confirm_role_deletion":
    "Are you sure you want to delete the role",
  "management.new_role": "New role",
  "management.changes_saved_successfully": "Changes saved successfully",
  "management.role_name": "Role name",
  "management.more": "More",
  "management.activity_report": "Activity report",
  "management.user_change": "User change",
  "management.new_user": "New user",
  "management.last_name": "Last name",
  "management.select_role": "Select role",
  "management.to": "To",
  "management.with_name": "With name",
  "management.with_title": "With title",
  "management.with_first_name": "With first name",
  "management.with": "With",
  "management.for_amount_of": "For the amount of",
  "management.logout_all_devices": "Logout from all devices using this profile",
  "management.confirm_logout_devices":
    "Are you sure you want to logout from all devices using this profile?",
  "management.deactivate_user": "Deactivate user",
  "management.confirm_user_deactivation":
    "Are you sure you want to deactivate the user",
  "management.deactivate": "Deactivate",
  "management.search_email_name_role": "Search by e-mail, name, and role.",
  "management.roles": "Roles",
  "management.team": "Team",
  "management.action_history": "Action history",
  "management.subscription_settings": "Subscription and settings",
  "management.transactions": "Transactions",
  "note.new_note": "New note",
  "note.content": "Content...",
  "note.error": "Error",
  "note.add": "Add",
  "note.cashier_printout": "In cashier printout",
  "note.cashier_note": "Cashier note",
  "profile.change_profile": "Change profile",
  "profile.new_profile": "New profile",
  "profile.board_member": "Board member",
  "profile.company": "Company",
  "profile.unique_ID": "Unique ID",
  "profile.manager": "Manager",
  "profile.manager_placeholder": "John Doe",
  "profile.residents": "Residents",
  "profile.copy_selected_emails": "Copy e-mail address of selected",
  "profile.management_board": "Management board",
  "ticket.created_in_last": "Created in the last",
  "ticket.in_condition": "In condition",
  "ticket.no_comments": "No comments on this ticket yet.",
  "ticket.delete_comment": "Delete comment",
  "ticket.confirm_deletion": "Are you sure you want to delete this comment",
  "ticket.comment_deleted": "Comment deleted",
  "ticket.edit_comment": "Edit comment",
  "ticket.change_signal": "Change signal",
  "ticket.send_signal": "Send signal",
  "ticket.issue_type": "Issue type",
  "ticket.select_user": "Select user",
  "ticket.official": "Official",
  "ticket.new": "New",
  "ticket.assigned_to_me": "Assigned to me",
  "ticket.in_progress": "In progress",
  "ticket.completed": "Completed",
  "ticket.filtered": "Filtered",
  "ticket.upload": "Upload",
  "helpers.error_check_data": "Please check the provided data and try again",
  "helpers.try_again": "Please try again.",
  "charge.delete_selected": "Delete selected obligations",
  "charge.delete_due": "Delete obligations",
  "charge.selected_for_deletion":
    "You have selected to delete the following obligations",
  "charge.generate_for_period":
    "With this action, you will regenerate the automatically billed obligations for the period",
  "charge.needed_if_change":
    "This is necessary for significant changes in the budget or attributes of a group of properties (e.g. occupancy) after they have already been generated.",
  "charge.payment_restored":
    "All made payments will be returned to the balance of the respective properties and will be used for automatic settlement at the end of the day.",
  "charge.regenerate": "Regenerate",
  "charge.recalc_auto_debt": "Recalculate automatically billed obligations",
  "charge.for_period": "For period",
  "charge.period": "Period",
  "charge.automatically_billed": "Automatically billed obligations",
  "charge.unit": "Unit",
  "charge.all_debt": "All obligations",
  "charge.to_property": "To property",
  "charge.current": "Current",
  "charge.overdue": "Overdue",
  "charge.total": "Total",
  "charge.search": "Search",
  "charge.search_capital": "Search",
  "charge.branch_numb": "Branch No.",
  "charge.condition": "Condition",
  "charge.from_date": "From date",
  "charge.to_date": "To date",
  "charge.model": "Model",
  "charge.billing_model": "Billing model",
  "charge.obligations": "Obligations",
  "charge.no_matter": "No matter",
  "charge.return_obligation": "Return obligation",
  "charge.balance": "Balance",
  "charge.cause": "Cause",
  "charge.return_to_balance": "Return to balance",
  "charge.fund": "Fund",
  "charge.current_capital": "Current",
  "charge.overdue_capital": "Overdue",
  "charge.regen_auto_debt": "Regenerate automatic obligations for the period",
  "component.charge-table.description_header":
    "Obligation to ${params.fund} from ${params.date}",
  "component.pay-by-card.transaction_fee_info":
    "Please note the additional administrative fee of 2% (but not less than 2 BGN), which applies to the amount of each payment. This fee is introduced to cover the costs associated with processing the transaction.",
  "fund.public_in_reports": "Public (present in reports)",
  "fund.balance": "Balance",
  "fund.create_fund": "Create a new fund",
  "fund.change_of": "Change of",
  "model.change": "Change model",
  "model.new": "New model",
  "model.attribute": "Attribute",
  "model.ic": "IC%",
  "model.to_checkout": "To checkout",
  "model.min_price": "Minimum price",
  "model.price": "Price",
  "model.foreach_perc": "5 BGN for each % of ideal parts, minimum 4.20",
  "settlement.confirm_payment_to": "Confirm payment to",
  "settlement.confirm_payment": "Are you sure you want to confirm payment from",
  "settlement.to": "to",
  "settlement.paid": "Paid",
  "settlement.payment_info": "Payment information",
  "settlement.payment_number": "Payment Number",
  "settlement.payment_to": "Payment to",
  "settlement.delete_payment": "Delete payment to",
  "settlement.delete_confirmation":
    "Are you sure you want to delete payment from",
  "settlement.payment_method": "Payment method",
  "settlement.deleted": "Deleted",
  "settlement.client_number": "Client Number",
  "settlement.show_deleted": "Show deleted",
  "settlement.payments": "Payments",
  "settlement.casheer": "Cashier",
  "settlement.fees": "Fees",
  "settlement.payment_reversed": "Payment reversed on",
  "settlement.reverse_transaction": "Reverse transaction",
  "settlement.reason_for_reverse": "Reason for reversing the transaction",
  "sandbox.demo_version": "Demo version",
  "sandbox.welcome": "Welcome to the demo version of Smart Entrance!",
  "sandbox.intro": "Here you can freely try many of the features we offer.",
  "sandbox.demo_obj":
    "For your convenience, we have created an object with a set of properties, funds, and payments that you can modify as you wish.",
  "sandbox.feedback":
    "Do you have questions or want to learn more about our services? Do not hesitate to send an inquiry to",
  "authwrap.letter": "Access with letter",
  "authwrap.password": "Access with password",
  "authwrap.demo": "Demo environment",
  "authwrap.agreement":
    "By submitting a registration request, you declare that you have read, agree to, and commit to complying with the",
  "license.terms_of_use": "terms of use",
  "login.loginas": "Login as user",
  "login.admin_loginas":
    "Administrators can log into the system as another user. Do you want to continue as",
  "login.link_send":
    "A letter with an access link will be sent to the specified address",
  "login.instruction_mail":
    "Within a few minutes, a letter with instructions for accessing the system will arrive at the specified address.",
  "login.nomail_info":
    'If this does not happen, try logging in again via "Access with letter". To use "Access with password", it is necessary to create one.',
  "login.pass_manage": "Change or create password",
  "login.understood": "Understood",
  "login.password": "Password",
  "login.repeat_password": "Repeat password",
  "login.tologin": "To login",
  "password.change": "Change password",
  "password.change2": "Change password",
  "password.successful_recovery": "Successful recovery",
  "password.recovery_mail":
    "A letter with a link to recover access to your user account has been sent to your email.",
  "register.tryout": "Try the platform's features without binding conditions",
  "register.existing":
    "If your building already uses Smart Entrance, you can log in",
  "register.demo": "Register",
  "tour.management": "Details on property management.",
  "tour.charges": "Obligations to your properties.",
  "tour.notes":
    "Notes. Here you can fill in any information that is for official use only and is not visible to clients/residents/owners.",
  "tour.messages":
    "Here you can track details of scheduled activities in your building - for example, when our cashier will visit you, for which date a general meeting is scheduled, when pest control is planned, etc.",
  "tour.info":
    "Here we place general information about your building - address, number and type of individual objects, services from COS Bulgaria, important phone numbers of service providers, etc.",
  "tour.docs":
    "Here you can familiarize yourself with important documents related to the management and maintenance of your building - minutes of General Meetings, contracts with suppliers, etc.",
  "tour.transaction_categories":
    "Specifying types of transactions allows creating reports grouped by transaction type in the 'Management -> Finance' section",
  "info.obligation_paid": "This obligation is paid and cannot be changed.",
  "info.obligation_generated1": "This obligation is generated automatically.",
  "info.obligation_generated2":
    "Any change will remove the information on how it was composed.",
  "warning.importFullReplacement":
    "Warning: The data in this table entirely replaces the already entered values for a given object. Removing rows is interpreted as 'deletion'.",
  "error.saving": "Error saving. Please try again or contact customer service.",
  "error.error_copying_table": "An error occurred while copying the table!",
  "error.error_occurred": "An error occurred",
  "error.error_try_later": "An error occurred. Please try again later.",
  "error.page_not_found": "Page not found",
  "error.file_upload_failed": "File upload failed",
  "stat.estates": "Number of properties",
  "stat.estates_growth": "Property growth (%YoY)",
  "stat.active_blocks": "Active objects",
  "stat.active_blocks_growth": "Object growth (%YoY)",
  "stat.deposits_per_month": "Payments per month",
  "stat.deposit_method_percent": "Payments by payment method",
  "stat.no_payments_data": "No payments received",
  "component.managementPage.financeOverviewHeader": "Financial Overview",
  "component.managementPage.managementBulkImportHeader": "Bulk Editing",
  "dashboard.open.signals": "Active Signals",
  "dashboard.no.signals.header": "No current tickets",
  "dashboard.no.signals.paragraph":
    "There are currently no open tickets for this block.",
};
